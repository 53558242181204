import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import TestApi from '@/views/TestApi.vue'
import TestSwiper from '@/views/TestSwiper.vue'
import GalleryUk from '@/views/gallery/GalleryUk.vue'
import GalleryItaly from '@/views/gallery/GalleryItaly.vue'
import GalleryCzech from '@/views/gallery/GalleryCzech.vue'
import GalleryGreece from '@/views/gallery/GalleryGreece.vue'
import GallerySpain from '@/views/gallery/GallerySpain.vue'
import GalleryRussia from '@/views/gallery/GalleryRussia.vue'
import GalleryKazakhstan from '@/views/gallery/GalleryKazakhstan.vue'
import GalleryLatvia from '@/views/gallery/GalleryLatvia.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/test_api',
    name: 'testApi',
    component: TestApi
  },
  {
    path: '/test_swiper',
    name: 'testSwiper',
    component: TestSwiper
  },
  {
    path: '/gallery/uk',
    name: 'GalleryUk',
    component: GalleryUk
  },
  {
    path: '/gallery/italy',
    name: 'GalleryItaly',
    component: GalleryItaly
  },
  {
    path: '/gallery/czech',
    name: 'GalleryCzech',
    component: GalleryCzech
  },
  {
    path: '/gallery/greece',
    name: 'GalleryGreece',
    component: GalleryGreece
  },
  {
    path: '/gallery/spain',
    name: 'GallerySpain',
    component: GallerySpain
  },
  {
    path: '/gallery/russia',
    name: 'GalleryRussia',
    component: GalleryRussia
  },
  {
    path: '/gallery/kazakhstan',
    name: 'GalleryKazakhstan',
    component: GalleryKazakhstan
  },
  {
    path: '/gallery/latvia',
    name: 'GalleryLatvia',
    component: GalleryLatvia
  },
  {
    path: '/:CatchAll(.*)',
    name: 'NotFound',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    /* console.log('router.. to=', to, 'router.. to.hash=', to.hash) */
    if (to.hash) {
      // Najdeme cílový element podle identifikátoru
      const element = document.querySelector(to.hash);
      if (element) {
        console.log('route.. elemnt=', element)
        // Posuneme stránku na cílový element
        element.scrollIntoView({ behavior: 'smooth' });
      }
      return
    } else {
      // Standardní chování routeru
      if (savedPosition) {
        return savedPosition
      } else {
        return { top: 0 }
      }
    }
  },
})

/* router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  loadLanguageAsync(lang).then(() => next())
}) */

export default router
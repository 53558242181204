<template>
    <div id="welcome-screen" class="welcome-screen position-relative">
        <div class="welcome-screen-overlay"></div>
        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
            <source src="@/assets/video.mp4" type="video/mp4">
        </video> 
        <img class="welcome-screen-logo" src="@/assets/images/logo.png" alt="kickoff-logo" width="250">
        <br>
    </div>
</template>

<script setup>
    const { onMounted, ref }=require("@vue/runtime-core")
    import SwiperLightBox from '../testSwiperLightBox.vue'

    const toggler = ref(false);

    function welcomeScreenSetOffset() {
        const navbar = document.getElementById('navbar')
        const welcomeScreen = document.getElementById('welcome-screen')
        const navbarHeight = navbar.offsetHeight
        if(welcomeScreen) welcomeScreen.style.marginTop = `-${navbarHeight}px`
    }

    window.addEventListener("load", (event) => {
        welcomeScreenSetOffset()
    });

    /* onMounted(() => {
        welcomeScreenSetOffset()
    }) */
</script>

<style>
    .welcome-screen {
        height: 100vh;
        margin-top: -90px;
    }

    .welcome-screen video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .welcome-screen-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
    }

    .welcome-screen-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
    }
</style>
<template>
    <section id="about-event" class="container">
        <div class="secondary-heading">Premium event</div>
        <h1>ESSENS KICK OFF TOUR 2024</h1>
        <p>{{ $t('web2024AboutT1') }}</p>
        <h4 class="mt-5">{{ $t('web2023AboutT2') }}</h4>
        <p>{{ $t('web2024AboutT3') }}</p>
        <div class="icons-about">
            <div class="row">    
                <div v-for="icon in iconsWrap" :key="icon" class="icon-col col-lg-4">
                    <img :src="icon.imgSrc" :alt="headingKey+'-icon'">
                    <h4>{{ $t(icon.headingKey) }}</h4>
                    <!-- Ru & Kz kokotiny -->
                    <template v-if="icon.ruKzBullshits && locale == 'ru' || icon.ruKzBullshits && locale == 'kz'">
                        <p v-html="$t(icon.textKeyRuKz)"></p>
                    </template>
                    <template v-else>
                        <p v-html="$t(icon.textKey)"></p>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useI18n } from "vue-i18n";
    const { locale } = useI18n()

    /* TODO imgSrc */
    const iconsWrap = [
        { imgSrc: 'https://www.essenskickoff.com/2022/img/icon/evolution.png', headingKey: 'webVzdHead', textKey: 'web2024VzdT', ruKzBullshits: true, textKeyRuKz: 'web2023Vzd2T' },
        { imgSrc: 'https://www.essenskickoff.com/2022/img/icon/inspiration.png', headingKey: 'webInspHead', textKey: 'web2024InspT' },
        { imgSrc: 'https://www.essenskickoff.com/2022/img/icon/challenge.png', headingKey: 'webNvHead', textKey: 'web2024NvT' },
    ];
</script>

<style>
    #about-event {
        text-align: center;
    }

    #about-event h1 {
        margin-bottom: 3rem;
        font-weight: bold;
    }

    /* #about-event p {
        margin-bottom: 2rem;
    } */

    .icons-about {
        margin-top: 3rem;
    }

    #about-event .icon-col {
        padding: 30px;
    }

    .icon-col h4 {
        margin-bottom: 1rem;
        transition: .3s;
    }

    .icon-col img {
        margin-bottom: 35px;
        padding: 20px 23px;
        max-width: 100px;
        display: inline-block;
        color: var(--primary);
        border: 1px solid var(--primary);
        font-size: 65px;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
    }

    .icon-col:hover img {
        border: 1px solid var(--secondary);
    }

    .icon-col:hover h4 {
        color: var(--secondary);
    }


    @media (min-width: 992px) {
        .icon-col img {
            max-width: 150px;
            padding: 36px 41px;
        }
    }
</style>
import { createI18n } from "vue-i18n";
import Vue from 'vue';
import savedLocales from './savedLocales.json'

console.log('i18n navigator.language:', navigator.language)
let browserLng = navigator.language
browserLng = browserLng.slice(0, browserLng.indexOf("-"))

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: browserLng,
    fallbackLocale: ['en', 'cs'],
    messages : {savedLocales},
})

async function loadAllMessages() {
  console.log('main.js i18n.global', savedLocales)
  if (savedLocales) {
    Object.entries(savedLocales).forEach(([lng, value]) => {
      i18n.global.setLocaleMessage(lng, value)
    })
  } else {
  const response = await fetch(`https://appgate.essensworld.com/translate.php?prefix=kickoff`)
  const messages = await response.json();
  Object.entries(messages).forEach(([lng, value]) => {
    i18n.global.setLocaleMessage(lng, messages[lng])
  });
}}

loadAllMessages()

export default i18n
<script>
import { ref, watch, toRefs } from 'vue'
import { Navigation, Pagination, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue';

/* Lazy load imgs */
   import VLazyImage from "v-lazy-image";

export default {
  components: {
    Swiper, 
    SwiperSlide,
    VLazyImage
  },
  props: {
    currentSlide: Number,
    thumbs: Object,
    lightbox: Boolean,
    images: Array
  },
  setup(props, {emit}) {
    const swiperRef = ref(null)

    const { currentSlide } = toRefs(props)

    const closeLightBox = (event) => {
      console.log('yxcyxc')
      emit('closeLightBox')
    }

    watch(currentSlide, () => {
      if(swiperRef.value !== null) {
        swiperRef.value.slideTo(props.currentSlide)
      }
    })

    const onSwiper = (swiper) => {
      swiperRef.value = swiper
    }

    console.log('carousel.vue... images:', props.images)

    return {
      swiperRef,
      onSwiper,
      Navigation, 
      Pagination, 
      Thumbs,
      closeLightBox,
      images: props.images,
      VLazyImage
    }
  }
}
</script>

<template>
  <Swiper @swiper="onSwiper" :slides-per-view="1" :space-between="50" :modules="[Navigation, Pagination, Thumbs]" navigation :pagination="{ clickable: true, dynamicBullets: true }" grab-cursor :preload-images="false" lazy :thumbs="{ swiper: thumbs }">
    <SwiperSlide v-for="img in images" :key="img"
        @click.self="closeLightBox"
        class="customSlide"
    >
      <v-lazy-image :src="img.url" src-placeholder="https://cdn-images-1.medium.com/max/80/1*xjGrvQSXvj72W4zD6IWzfg.jpeg" alt="img" />
    </SwiperSlide>
  </Swiper>
</template>

<style scoped>
  .customSlide {
    display: grid;
    place-items: center;
    height: 100vh;
  }
</style>
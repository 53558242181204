import { ref } from "vue"

const getTestData = () => {
    const testData = ref([])
    const error = ref(null)

    const loadTestData = async () => {
        try {
            /* let data = await fetch('https://appgate.essensworld.com/static.php?dir=kickoff_23/hotels') */
            let data = await fetch('https://appgate.essensworld.com/static.php?dir=banners/cz')
            if(!data.ok) {
                throw Error('no data avaible..')
            }
            testData.value = await data.json()
        } catch (err) {
            error.value = err.message
            console.log(error.value)
        }
    }

    return { testData, error, loadTestData }
}

export default getTestData
<template>
    <section id="after-movies">
        <div class="secondary-heading text-center">ESSENS KICK OFF</div>
        <h1 class="text-center mb-5"> {{ $t('webVidea') }} </h1>
        <div id="after-movies-container" class="container-fluid container-xxl px-5 position-relative">
            <!-- <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/392487362?h=c8cbd4ad52" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div> -->
            <swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="30"
                navigation
                :autoplay="swiperOptions.Autoplay"
                :breakpoints="swiperOptions.breakpoints"
            >
                <template v-for="afterMovie in afterMovies" :key="afterMovie">
                    <swiper-slide class="my-2">
                        <div class="mb-3 d-flex gap-2 text-center align-items-center justify-content-center" style="margin-left: -10px">
                            <img :src="'https://www.essenskickoff.com/2022/img/flag/'+afterMovie.flag+'.png'" alt="United Kingdom" style="width: 25px;">
                            <h4 class="mb-0"> {{ afterMovie.name }} </h4>
                        </div> 
                        
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" :src="afterMovie.src" allow="fullscreen;"></iframe>
                        </div>
                    </swiper-slide>
                </template>
            </swiper>
        </div>
    </section>
</template>

<script>
    // import Swiper core and required modules
    import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';
    import 'swiper/css/autoplay';


    // Import Swiper styles
    export default {
        components: {
        Swiper,
        SwiperSlide,
        },
        setup() {
            const swiperOptions = {
                breakpoints: {
                    992: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                },
                Autoplay: {
                    pauseOnMouseEnter: true,
                    /* disableOnInteraction: false */
                }
            }

            const afterMovies = [
                { name: 'Czech Republic', src: 'https://player.vimeo.com/video/911107645?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'cz' },
                { name: 'Spain', src: 'https://player.vimeo.com/video/919532501?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'es' },
                { name: 'Greece', src: 'https://player.vimeo.com/video/929797411?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'gr' },
                { name: 'Italy', src: 'https://player.vimeo.com/video/929797382?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'it' },
                { name: 'Belgium', src: 'https://player.vimeo.com/video/929797289?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'belgium' },
                { name: 'Russia', src: 'https://player.vimeo.com/video/919532369?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'ru' },
                { name: 'Kazakhstan', src: 'https://player.vimeo.com/video/919532446?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'kz' },
                { name: 'Latvia', src: 'https://player.vimeo.com/video/929797354?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', flag: 'lt' },
            ]

            return {
                modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
                swiperOptions,
                afterMovies
            };
        },
        mounted() {
            const btnPrev = document.querySelector('.swiper-button-prev')
            const btnNext = document.querySelector('.swiper-button-next')
            const container = document.querySelector('#after-movies-container')
            container.append(btnPrev)
            container.append(btnNext)
        }
    };
</script>

<style>

    #after-movies {
        background-color: var(--grey-bg);
    } 


    /* .swiper-slide {
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
    } */
    .swiper-button-next, .swiper-button-prev {
        top: calc(50% + 21px);
        z-index: 1;
    }

    .swiper-button-next {
        right: 5px;
    }
    .swiper-button-prev {
        left: 5px;
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        color: var(--secondary);
    }

    /* @media (max-width: 1399px) {
        .swiper-button-next {
            right: 5px;
        }
        .swiper-button-prev {
            left: 5px;
        }
    } */
</style>
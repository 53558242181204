import { ref } from "vue"

const getImagesFromStaticByFolder = () => {
    const images = ref([])
    const error = ref(null)

    const loadImages = async (folder) => {
        /* console.log('getImagesFromStaticByFolder.js folder:', folder) */
        try {
            let data = await fetch(`https://appgate.essensworld.com/static.php?dir=${folder}`)
            if(!data.ok) {
                throw Error('no data avaible..')
            }
            images.value = await data.json()
        } catch (err) {
            error.value = err.message
            console.log(error.value)
        }
    }

    return { images, error, loadImages }
}

export default getImagesFromStaticByFolder
<template>
  <footer class="footer " style="background-color: black;">
    <div class="containter">
        <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
            <img src="@/assets/images/logo.png" alt="kickoff-logo" class="mb-5 mb-sm-0" style="max-width:200px;">
            <div class="footer-nav text-center text-sm-start">
                <ul class="mb-0 ps-0 ps-sm-4 d-flex flex-wrap">
                    <li v-for="link in footerNav" :key="link" class="footer-nav-link">
                        <!-- {{link.name}} -->
                        <router-link :to="link.link" >
                            <template v-if="!link.isNotKey">
                                <span v-html="$t(link.name)"></span>
                            </template>
                            <template v-else>
                                <b><i v-html="link.name" style="color:white"></i></b>
                            </template>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Facebook button share -->
       <!--  <div class="fb-share-button mb-1 mt-4 text-center" data-href="https://test.kickoff.com/" data-layout="button_count">
            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftest.kickoff.com%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">
                <i class="fa fa-facebook-official" style="color: #4267b2; font-size: 30px;" aria-hidden="true"></i>
            </a>
        </div> -->
        <p class="copyright text-center mt-5">
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            Copyright © ESSENS  {{ year }}
            All rights reserved | Design: <a href="https://www.essensworld.com" target="_blank">ESSENS</a> &amp; <a href="https://colorlib.com" target="_blank">Colorlib</a>    
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
        </p>
    </div>
  </footer>
</template>

<script setup>
    import { RouterLink } from "vue-router"

    import menuData from "@/composables/menuData"
    const footerNav = menuData

    const year = new Date().getFullYear()
</script>

<style>
    footer {
        padding: 50px 0 20px 0;
    }
    .footer * {
        color: #ebebeb;
    }
    .footer-nav-link {
        padding: 10px 10px;
        list-style-type: none;
        width: 50%;
    }

    .copyright {
            font-size: 14px;
    }

    @media (min-width: 575px) {
        .footer-nav-link {
            width: 33%
        }
    }
    @media (min-width: 768px) {
        .footer-nav-link {
            width: 25%
        }
    }
</style>
<template>
  <!-- <div v-if="translations">
    <div v-for="time in testData.time" :key="time">
      {{ time }}
    </div>
    {{ testData }}
    
    {{ translations }}
    <br>
    {{ translations.cs['priceDisplay'] }}
  </div>
  <div v-else>loading..</div> -->
  <div>
    'code': {{ $t('catCode') }}
  </div>

  <div v-if="testData">
    <div class="container-fluid">
        <!-- <img :src="img.url" alt="img"> -->
        <div class="row">
          <div v-for="img in testData" :key="img" class="col-lg-4" style="margin-top:25px;" >
            {{ img.filename }}<br>
            <v-lazy-image :src="img.url" src-placeholder="https://cdn-images-1.medium.com/max/80/1*xjGrvQSXvj72W4zD6IWzfg.jpeg" alt="img" />
          </div>
        </div>
    </div>
  </div>
  <div v-else>loading...</div>
  <div v-if="error"> Něco se pokazilo :( <br> Zkuste prosím znova později </div>
  
</template>

<script setup>
  /* Lazy load imgs */
   import VLazyImage from "v-lazy-image";

  /* API */
  import getTestData from '../composables/getTestData.js'

  const { testData, error, loadTestData } = getTestData()
  loadTestData()
  console.log('testData', testData)


  /* API */
  /* import getTranslations from '../composables/getTranslations.js'

  const { translations, error, loadTranslations } = getTranslations()
  loadTranslations() */
</script>

<style>
  /* .v-lazy-image {
    opacity: 0;
    transition: opacity 2s;
    width: 100%;
  }
  .v-lazy-image-loaded {
    opacity: 1;
  } */
</style>
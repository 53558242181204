<template>
  <template v-if="images.length !== 0"> <!-- {{ images }} -->
    <LightBox :images="images" />
  </template>
  
</template>

<script>
import LightBox from "@/components/lightbox/lightbox.vue"

export default {
  props: {
    currentSlide: Number,
    thumbs: Object,
    lightbox: Boolean,
    images: Array
  },
  components: {
    LightBox,
  },
  setup(props){
    console.log('testSwiperLightBox.vue... images:', props.images)
    return {
      images: props.images,
    }
  }
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
  color: #2c3e50;
  margin: 60px auto;
} */
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

// i18n
import { createI18n } from "vue-i18n"
import i18n from './locales/i18n.js'

// Global styles
import './assets/css/bootstrap.min.css'
import './assets/css/main.css'
import '@/assets/font-awesome/css/font-awesome.css'

// Js
import * as bootstrap from './assets/js/bootstrap.min.js'


const app = createApp(App)

app.use(router)
  .use(i18n)
  .use(CoolLightBox)
  .mount('#app')

/* createApp(App).use(router).use(CoolLightBox).mount('#app') */
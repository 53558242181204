<template>
    <div id="mobile-nav" class="closed d-flex flex-column align-items-center">
        <div class="close-btn fs-4 m-3 m-s-auto ms-auto" @click="closeMobileNav()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        <div v-for="link in links" :key="link" class="mobile-nav-link">
            <router-link :to="link.link">
                <template v-if="!link.isNotKey">
                    <span v-html="$t(link.name)"></span>
                </template>
                <template v-else>
                    <b>
                        <span v-html="link.name"></span>
                    </b>
                </template>
            </router-link>
        </div>
    </div>
    <div class="overlay d-none" @click="closeMobileNav()"></div>
</template>

<script setup>

    const props = defineProps({
        links: Array
    })

    const emit = defineEmits(['closeMobileNav']);

    function closeMobileNav() {
        emit('closeMobileNav')
    }

</script>

<style>
    #mobile-nav {
        width: 250px;
        max-width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 11;
        background-color: var(--primary);
        transition: 0.3s;
    }

    #mobile-nav.closed {
        right: -250px;
    }

    .mobile-nav-link {
        padding: 10px;
    }

    .mobile-nav-link span {
       color: white;
    }

    .close-btn i {
        color: var(--secondary);
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .5;
        z-index: 9;
    }

    .close-btn {
        cursor: pointer;
    }
</style>
<template>
    <section id="photo-gallery">
        <div class="container container-xl-fluid">
            <div class="secondary-heading text-center">ESSENS KICK OFF</div>
            <h1 class="text-center mb-5"> {{ $t('webGallery') }} </h1>
            <div class="row">
                <div v-for="gallery in galleries" :key="gallery" class="col-xl-3 col-lg-4 col-6 my-4 my-lg-5 px-lg-4">
                    <router-link :to="gallery.href" class="gallery-thumbnail d-block text-center">
                        <img :src="gallery.imgSrc" :alt="gallery.name + ' image'">
                        <img class="mt-3 mb-1" :src="'https://www.essenskickoff.com/2022/img/flag/'+gallery.flag+'.png'" alt="United Kingdom" style="width: 25px;">
                        <div class="thumbnail-h ">{{ gallery.name }}</div>
                        <!-- <button class="btn btn-primary d-table mx-auto mt-3">Zobrazit</button> -->
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>

    const galleries = [
        { name: 'Czech Republic', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/czechia/KO TOUR 2024 BRNO-1.jpg', href: '/gallery/czech', flag: 'cz' }, /* ESSENS Kick OFF Tour 2024*/
        { name: 'Spain', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/spain/KO TOUR 2024 MALAGA-52.jpg', href: '/gallery/spain', flag: 'es' },
        { name: 'Greece', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/recko/KO TOUR 2024 ATHENY-16.jpg', href: '/gallery/greece', flag: 'gr' },
        { name: 'Italy', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/italy/KO TOUR 2024 BOLOGNA-9.jpg', href: '/gallery/italy', flag: 'it' },
        { name: 'Belgium', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/belgie/KO TOUR 2024 BRUSEL-56.jpg', href: '/gallery/uk', flag: 'belgium' },
        { name: 'Kazakhstan', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/kazach/KO TOUR 2024 ALMATY-13.jpg', href: '/gallery/kazakhstan', flag: 'kz' },
        { name: 'Latvia', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/riga/KO TOUR 2024 RIGA-1.jpg', href: '/gallery/latvia', flag: 'lt' },
        { name: 'Russia', imgSrc: 'https://static.essensworld.com/images/kickoff_24/web/rusko/KO TOUR 2024 MOSKVA-77.jpg', href: '/gallery/russia', flag: 'ru' },
    ]
</script>

<style>  

    .thumbnail-h {
        color: var(--primary);
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
    }

    .gallery-thumbnail {
        /* border: 1px solid var(--secondary); */
    }
</style>